import makeStyles from '@mui/styles/makeStyles';
import SpendingSettings from '@truelinkfinancial/spending-settings-web-sdk';
import axios from 'axios';
/* eslint-disable import/namespace, import/default */
import pkceChallenge from 'pkce-challenge';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

const useStyles = makeStyles({
  sdkContainer: {
    '& input[type="text"]': {
      border: '0',
      boxShadow: 'none',
      padding: '8.5px 14px 8.5px 0',
      margin: '0',
      height: '40px',
    },

    '& input[disabled]': {
      backgroundColor: '#fff',
    },
    '& h1': {
      display: 'none',
    },
    '& h2': {
      fontSize: '26px',
    },
  },
  '@global': {
    'input#merchant_limit[type="number"], input#merchant_name[type="text"]': {
      height: '40px !important',
      marginBottom: '0 !important',
    },
    'input#merchant-cluster[type="text"]': {
      boxShadow: 'none',
      border: 'none',
      margin: '0',
    },
    '#merchant_info': {
      boxShadow: 'none',
    },
  },
});

export default function MerchantSettingsV2({ cardReference, scope }) {
  const classes = useStyles();

  const [codeVerifier, setCodeVerifier] = useState(null);
  const [code, setCode] = useState(null);

  useEffect(() => {
    const getAuthorizationCode = async () => {
      try {
        const { code_challenge: codeChallenge, code_verifier } = await pkceChallenge();
        setCodeVerifier(code_verifier);
        const response = await axios.post(
          RailsRoutes.api_v2_authorization_grants_path({ format: 'json' }),
          {
            data: {
              attributes: { scope, cardReference, codeChallenge, codeChallengeMethod: 'S256' },
            },
          },
        );
        setCode(response.data.data.attributes.code);
      } catch (error) {
        window.Truelink.flash('error', 'Could not get an authorization code.');
      }
    };
    getAuthorizationCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSpendingSettings = !!code && !!codeVerifier;

  return (
    <div className={classes.sdkContainer}>
      {renderSpendingSettings && (
        <SpendingSettings
          baseUrl={''}
          code={code}
          codeVerifier={codeVerifier}
          showAddMerchantButton
          visibilityConfig={{
            merchantSettings: true,
          }}
        />
      )}
    </div>
  );
}

MerchantSettingsV2.propTypes = {
  scope: PropTypes.string.isRequired,
  cardReference: PropTypes.string,
};
