import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import _isEmpty from 'underscore/modules/isEmpty';
import _keys from 'underscore/modules/keys';
import _map from 'underscore/modules/map';
import _object from 'underscore/modules/object';
import DisbursementDetailActions from './DisbursementDetailActions';
import DisbursementPayeeDetails from './disbursement_payee_details';
import DashboardActions from 'react/member/actions/dashboard_actions';
import CategoryDropdown from 'react/member/components/dashboard/CategoryDropdown';
import ApproverInfoSection from 'react/member/components/dashboard/disbursements/ApproverInfoSection';
import DashboardBudgetItemAttachments from 'react/member/components/dashboard/disbursements/DashboardBudgetItemAttachments';
import DetailBalanceInfo from 'react/member/components/dashboard/disbursements/DetailBalanceInfo';
import DisbursementDeleteConfirmationModal from 'react/member/components/dashboard/disbursements/DisbursementDeleteConfirmationModal';
import PostageType from 'react/member/components/dashboard/disbursements/PostageType';
import DashboardPayee from 'react/member/components/dashboard/payees/DashboardPayee';
import PayeeDropdown from 'react/member/components/dashboard/payees/PayeeDropdown';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import DashboardDisbursementStore from 'react/member/stores/DashboardDisbursementStore';
import DashboardPayeeStore from 'react/member/stores/DashboardPayeeStore';
import PaymentMethodDropdown from 'react/shared/components/PaymentMethodDropdown';
import DashboardClientStore from 'react/shared/stores/DashboardClientStore';
import { asMoney } from 'react/shared/utils/Money';
import { getPostageTypeDisplayValue } from 'react/shared/utils/Postal';
import bindAll from 'react/shared/utils/bind_all';
import tlFieldTransformers from 'react/shared/utils/tl_field_transformers';

export default class DashboardDisbursementDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      isDeleteConfirmationModalOpen: false,
      payees: this.props.payees || [],
      // The next three state values are global to the disbursement, whether it is a multi-category (group)
      // disbursement or a single disbursement
      payee: this.props.disbursement.payee,
      paymentMethod: this.props.disbursement.payment_method,
      showNewPayeeModal: false,
      postageType: this.props.disbursement.postage_code,
      disposition:
        this.props.disbursement.payee_disposition ||
        (this.props.disbursement.payee && this.props.disbursement.payee.disposition),
      useDisbursementPayeeOverride: this.props.disbursement.override_payee_fields,
    };

    bindAll(this);
  }

  // In an ideal world, we wouldn't have to be repopulating context from props. But, because we do
  // weird stuff to render this (i.e., rendering it via datatables/jquery and forcing it to mount
  // via ReactDOM.render() from DashboardPaginatedDisbursements), we are breaking normal React-y flow here.
  // This should not be considered normal or expected behavior for context population, and should be
  // removed whenever we can refactor our front-end code to use proper React all the way through.
  getChildContext() {
    return {
      client: this.props.disbursement.beneficiary,
      togglePayeeForm: this.toggleNewPayeeModal,
    };
  }

  componentDidMount() {
    DashboardPayeeStore.on('payees.add', this.onAddBeneficiaryPayee);
    DashboardPayeeStore.on('payees.fetch', this.onFetchBeneficiaryPayee);

    DashboardDisbursementStore.on('disbursement.update', this.onUpdateSuccess);
  }

  componentWillUnmount() {
    DashboardPayeeStore.off('payees.add', this.onAddBeneficiaryPayee);
    DashboardPayeeStore.off('payees.fetch', this.onFetchBeneficiaryPayee);

    DashboardDisbursementStore.off('disbursement.update', this.onUpdateSuccess);
  }

  isApprovalRequiredView() {
    return ['additional_approval', 'pending', 'proposed'].includes(this.props.statusFilter);
  }

  toggleEdit(e) {
    const client = this.props.disbursement.beneficiary;
    DashboardActions.fetchPayees(client.slug);

    if (this.state.editing) {
      return this.updateEntity(e);
    }
    return this.setState({ editing: true });
  }

  removeHold(e) {
    e.preventDefault();

    const { disbursement } = this.props;
    if (this.canRemoveHold(disbursement.status)) {
      const payload = this.removeHoldAttributes(disbursement);
      DashboardActions.updateDisbursement(disbursement.id, payload);
    }
  }

  putOnHold(e) {
    e.preventDefault();

    const { disbursement } = this.props;
    if (this.canGoOnHold(disbursement.status)) {
      const payload = [{ name: 'disbursement[status]', value: `${disbursement.status} on Hold` }];
      DashboardActions.updateDisbursement(disbursement.id, payload);
    }
  }

  sendDisbursementBackToPending(e) {
    e.preventDefault();

    const { disbursement } = this.props;
    DashboardActions.returnDisbursementForRevision(disbursement.id);
  }

  canRemoveHold(status) {
    return (
      status === 'Pending on Hold' ||
      status === 'Proposed on Hold' ||
      status === 'Additional Approval on Hold'
    );
  }

  canGoOnHold(status) {
    return status === 'Pending' || status === 'Proposed' || status === 'Additional Approval';
  }

  removeHoldAttributes(disbursement) {
    let attrs;
    switch (disbursement.status) {
      case 'Pending on Hold': {
        attrs = [{ name: 'disbursement[status]', value: 'Pending' }];

        break;
      }
      case 'Additional Approval on Hold': {
        attrs = [{ name: 'disbursement[status]', value: 'Additional Approval' }];

        break;
      }
      case 'Proposed on Hold': {
        attrs = [{ name: 'disbursement[status]', value: 'Proposed' }];

        break;
      }
      // No default
    }
    return attrs;
  }

  updateEntity(e) {
    e.preventDefault();

    const { payee, paymentMethod } = this.state;

    if (!payee && !['Card', 'Direct Debit'].includes(paymentMethod)) {
      Truelink.flash('error', 'Failed to update disbursement. You must select a payee.');
      return;
    }

    const primaryId = this.props.disbursement.id;
    const disbursementValuesFromDom = this.convertInputsToObject(
      $(ReactDOM.findDOMNode(this)).find(`.js-edit-entity-${primaryId}`).serializeArray(),
    );
    const payload = {
      disbursement: {
        payment_method: this.state.paymentMethod,
        payee_id: this.state.payee && this.state.payee.id,
        postage_code: this.state.postageType,
        check_memo_line: disbursementValuesFromDom.check_memo_line,
        card_id: disbursementValuesFromDom.card_id,
      },
    };

    const dcaIds = this.props.disbursement.categories.map((category) => category.dca_id);
    payload.disbursements = dcaIds.map((id) => {
      const rawValues = $(ReactDOM.findDOMNode(this))
        .find(`.js-edit-dca-entity-${id}`)
        .serializeArray();
      const dcaValuesFromDom = this.convertInputsToObject(rawValues);
      return {
        id,
        amount: dcaValuesFromDom.amount, // String
        category: dcaValuesFromDom.category,
        memo: dcaValuesFromDom.memo,
        selected_insert: dcaValuesFromDom.selected_insert,
      };
    });
    DashboardActions.updateDisbursement(primaryId, payload);

    return this.setState({
      editing: false,
    });
  }

  onUpdateSuccess(updatedDisbursement) {
    if (this.props.disbursement.id === updatedDisbursement.id) {
      return this.setState({ editing: false });
    }
  }

  // only the multi-category disbursements need this step: break out of disbursement[] and make easy to parse
  // in the backend
  convertInputsToObject(disbursementInputs) {
    return _object(
      _map(disbursementInputs, (input) => [
        input.name.replace(/disbursement\[(.*)]/, '$1'),
        input.value,
      ]),
    );
  }

  editableField(label, value, key, newLine) {
    let dataClass = 'new-form__data';
    let fieldClass = 'new-form-field';
    if (newLine) {
      dataClass += ' new-form__data--newline';
      fieldClass = ' block';
    }
    dataClass += newLine ? ' new-form__data--newline' : '';
    return (
      <div className={fieldClass} key={key}>
        <div className="new-form__label">{label}</div>
        <div className={dataClass}>{value}</div>
      </div>
    );
  }

  payeeForm() {
    if (!this.state.showNewPayeeModal) {
      return null;
    }
    const client = this.props.disbursement.beneficiary;
    const { disbursement } = this.props;

    return (
      <DashboardPayee
        className="dashboard-new-payee"
        clientSlug={client.slug}
        displayedPaymentMethods={disbursement.beneficiary.trust.displayed_payment_types}
        onClose={this.closeNewPayeeModal}
        paymentMethod={this.state.paymentMethod}
        userRoles={this.props.userRoles}
      />
    );
  }

  toggleNewPayeeModal() {
    this.setState((prevState) => ({
      showNewPayeeModal: !prevState.showNewPayeeModal,
    }));
  }

  closeNewPayeeModal() {
    this.setState({ showNewPayeeModal: false });
  }

  updatePaymentMethod(evt) {
    return this.setState({
      paymentMethod: evt.target.value,
      payee: null,
      disposition: null,
    });
  }

  paymentType() {
    const entity = this.props.disbursement;
    const formClass = `js-edit-payment-type-${entity.id}`;

    const { disbursement } = this.props;
    const { paymentMethod, payee } = this.state;
    const { status } = disbursement;

    const checkLink =
      paymentMethod === 'External Check' && status === 'Processed' ? (
        <span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a
            href={RailsRoutes.dashboard_disbursement_view_check_path(disbursement.id)}
            rel="noreferrer"
            target="_blank"
          >
            View check
          </a>
        </span>
      ) : undefined;

    if (this.state.editing && !['Processed', 'Cleared'].includes(status)) {
      return (
        <div className={formClass}>
          <PaymentMethodDropdown
            displayedPaymentMethods={disbursement.beneficiary.trust.displayed_payment_types}
            formType="edit"
            handlePaymentMethod={this.updatePaymentMethod}
            hideLabel
            isEditable
            payee={payee}
            paymentMethod={paymentMethod}
          />
        </div>
      );
    }
    return (
      <div>
        {$tlf.domains.paymentMethodsMap[paymentMethod]}
        {checkLink}
      </div>
    );
  }

  cardDropdown() {
    if (!this.state.editing || this.state.paymentMethod !== 'Card') {
      return;
    }

    const { disbursement } = this.props;
    const formClass = `js-edit-entity-${disbursement.id}`;
    const client = DashboardClientStore.get(disbursement.beneficiary.slug);

    let nonClosedCards;

    if (client) {
      nonClosedCards = client.cards.filter((card) => card.status !== 'CLOSED');
    } else {
      nonClosedCards = [];
    }

    const cards = nonClosedCards.map((card) => (
      <option key={card.slug} value={card.slug}>
        {card.name}
      </option>
    ));
    const selectedCard = nonClosedCards.find((card) => card.id === disbursement.card_id);
    const selectCard = (() => {
      if (_isEmpty(cards)) {
        const uri = window.location.href.split('#')[0];
        const link = `${uri}?reload=1#client-card-tab`;

        return (
          <div className="new-form__callout new-form__callout--warning">
            {"There are no True Link Cards linked to this beneficiary's account."}
            <br />
            Please click <a href={link}>here</a> to link a True Link Card to this beneficiary.
          </div>
        );
      }
      return (
        <select defaultValue={selectedCard && selectedCard.slug} name="disbursement[card_id]">
          {cards}
        </select>
      );
    })();

    return (
      <form className={formClass}>
        <div className="new-form__label">
          <label htmlFor="disbursement[card_id]">Card</label>
        </div>
        <div className="new-form__data">{selectCard}</div>
      </form>
    );
  }

  updatePostageType(evt) {
    return this.setState({
      postageType: evt.target.value,
    });
  }

  postageType() {
    const { disbursement } = this.props;
    const { paymentMethod, postageType } = this.state;

    if (paymentMethod !== 'Check') return;

    const formClass = `js-edit-entity-${disbursement.id}`;
    const selectedPostage = postageType ? postageType.toString() : '1';

    if (this.state.editing) {
      return (
        <div className={formClass}>
          <PostageType
            codeCallback={this.updatePostageType}
            inputName="disbursement[postage_code]"
            selectedPostage={selectedPostage}
          />
        </div>
      );
    }
    return (
      <div className="new-form-field">
        <div className="new-form__label">Postage Type:</div>
        <div className="new-form__data">{getPostageTypeDisplayValue(postageType)}</div>
      </div>
    );
  }

  benefitToString(value) {
    if (value) {
      return 'YES';
    }
    return 'NO';
  }

  benefitsInformation() {
    const trustBeneficiary = this.props.disbursement.beneficiary;
    const html = [];
    trustBeneficiary.government_benefits_trust_beneficiaries.forEach((govtBenefit, index) => {
      const benefitActiveYesNo = this.benefitToString(govtBenefit.beneHasBenefit);
      const div = (
        <div className="new-form-field" key={index}>
          <div className="new-form__label">{govtBenefit.name}:</div>
          <div className="new-form__data">
            <span>
              {benefitActiveYesNo} {govtBenefit.amount}
            </span>
          </div>
        </div>
      );
      html.push(div);
    });

    return html;
  }

  handlePayeeChosen(payee) {
    const { disbursement } = this.props;
    this.setState({
      payee,
      disposition: payee && payee.disposition,
      useDisbursementPayeeOverride: payee.id === disbursement.payee.id,
    });
  }

  handlePayeeFieldChange(field, value) {
    this.setState({
      [field]: value,
    });
  }

  onAddBeneficiaryPayee(payee) {
    return this.setState({
      payee,
      paymentMethod: payee.supported_payment_methods[0],
      disposition: payee.disposition,
      payees: DashboardPayeeStore.getPayees(),
      postageType: null,
    });
  }

  onFetchBeneficiaryPayee(payees, bool) {
    return this.setState({
      payees,
      payeesLoaded: bool,
    });
  }

  payeeSelect() {
    const { disbursement } = this.props;
    const { paymentMethod, payees, editing, payee } = this.state;

    if (editing && !['Processed', 'Cleared'].includes(disbursement.status)) {
      if (['Check', 'External Check', 'EFT', 'Wire'].includes(paymentMethod)) {
        const key = payees.length ? 'loaded' : 'not loaded';
        return (
          <div>
            <div className="new-form__label">Payee:</div>
            <div className="new-form__data">
              <PayeeDropdown
                btnStyle
                canCreatePayee={!this.props.userRoles.viewOnly}
                currentClientId={this.props.disbursement.beneficiary.id}
                currentPaymentMethod={paymentMethod}
                handlePayeeChosen={this.handlePayeeChosen}
                hideLabel
                isEditing={editing}
                key={key}
                payees={payees}
                payeesLoaded
                selectedPayee={payee}
                togglePayeeForm={this.toggleNewPayeeModal}
              />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="new-form-field">
          <div className="new-form__label">Payee:</div>
          <div className="new-form__data">{disbursement.payee_name || (payee && payee.name)}</div>
        </div>
      );
    }
  }

  payeeDropdown() {
    const { disbursement } = this.props;
    const { payee, useDisbursementPayeeOverride } = this.state;
    let clientAccountNumber, city, state, street1, street2, zip;
    // if there is a payee_name then the disbursement is already processed
    // so we should use the payee_<attrs>
    if (useDisbursementPayeeOverride || disbursement.payee_name) {
      clientAccountNumber = disbursement.payee_client_account_number;
      street1 = disbursement.payee_street1;
      street2 = disbursement.payee_street2;
      city = disbursement.payee_city;
      state = disbursement.payee_state;
      zip = disbursement.payee_zip;
    } else {
      clientAccountNumber = payee && payee.client_account_number;
      street1 = payee && payee.address_attributes && payee.address_attributes.street1;
      street2 = payee && payee.address_attributes && payee.address_attributes.street2;
      city = payee && payee.address_attributes && payee.address_attributes.city;
      state = payee && payee.address_attributes && payee.address_attributes.state;
      zip = payee && payee.address_attributes && payee.address_attributes.zip;
    }
    const bankRoutingNumber =
      disbursement.payee_bank_routing_number || (payee && payee.bank_routing_number);
    const bankAccountNumber =
      disbursement.payee_bank_account_number || (payee && payee.bank_account_number);
    const specialInstructions =
      disbursement.payee_special_instructions || (payee && payee.special_instructions);
    const disposition = disbursement.payee_disposition || (payee && payee.disposition);
    const swiftCode = disbursement.payee_swift_code || (payee && payee.swift_code);

    return (
      <div>
        {this.payeeSelect()}
        <DisbursementPayeeDetails
          addressAttributes={{
            street1,
            street2,
            city,
            state,
            zip,
          }}
          bankAccountNumber={bankAccountNumber}
          bankRoutingNumber={bankRoutingNumber}
          clientAccountNumber={clientAccountNumber}
          disbursement={disbursement}
          disposition={disposition}
          editExternalCheckDisposition={this.props.editExternalCheckDisposition}
          isEditing={this.state.editing}
          onFieldChange={this.handlePayeeFieldChange}
          paymentMethod={this.state.paymentMethod}
          specialInstructions={specialInstructions}
          statusFilter={this.props.statusFilter}
          swiftCode={swiftCode}
        />
      </div>
    );
  }

  organizationSlug() {
    return this.props.disbursement.beneficiary.organization.slug;
  }

  canPrintDisbursement() {
    return this.props.disbursement.status !== 'Draft';
  }

  canDeleteDisbursement() {
    return this.props.disbursement.can_be_deleted;
  }

  deletePendingDisbursement() {
    this.setState({ isDeleteConfirmationModalOpen: true });
  }

  closeDeleteConfirmationModal() {
    this.setState({ isDeleteConfirmationModalOpen: false });
  }

  // Careful: this function only renders the disbursement category level fields for editing. The other fields
  // (e.g., Payment Type, Payee) are rendered in other functions of this component
  renderEditableFields(disbursementCategoryAmount) {
    const fields = [{ amount: 'Amount:' }, { category: 'Category:' }, { memo: 'Internal Notes:' }];

    const inputNamePrefix = 'disbursement';

    return fields.map((field, i) => {
      let newLine;
      const name = _keys(field)[0];
      const label = field[name];
      const value = disbursementCategoryAmount[name];

      if (this.state.editing) {
        const formClass = `js-edit-dca-entity-${disbursementCategoryAmount.dca_id}`;
        const symbol = name === 'amount' ? '$' : '';
        newLine = false;
        const slug = this.organizationSlug();
        const input = (() => {
          switch (name) {
            case 'category': {
              return (
                <CategoryDropdown
                  categoryNumber={disbursementCategoryAmount.dca_id}
                  hideLabel
                  initialValue={disbursementCategoryAmount.category}
                  isExistingRecord
                  isRecurringDisbursement={false}
                  organizationSlug={slug}
                />
              );
            }
            case 'memo': {
              const inputName = `${inputNamePrefix}[${name}]`;
              return <textarea defaultValue={value} name={inputName} style={{ width: '100%' }} />;
            }
            default: {
              const inputName = `${inputNamePrefix}[${name}]`;
              const style = name === 'amount' ? { marginLeft: 5, width: 110 } : undefined;

              return <input defaultValue={value} name={inputName} style={style} type="text" />;
            }
          }
        })();

        return this.editableField(
          label,
          <form className={formClass} onSubmit={this.updateEntity}>
            {symbol}
            {input}
          </form>,
          i,
          newLine,
        );
      }
      const formattedValue = (() => {
        if (value) {
          switch (name) {
            case 'amount':
              return <span>{asMoney(Number(value))}</span>;
            default:
              return <span>{value}</span>;
          }
        } else {
          const naText = (() => {
            switch (name) {
              case 'category':
                return 'Category not set';
              case 'memo':
                return 'No note';
            }
          })();

          return <span className="italic">{naText}</span>;
        }
      })();

      return this.editableField(label, formattedValue, i);
    });
  }

  renderDescription(entity) {
    if (!entity.requestor_description) return null;

    return (
      <div className="new-form-field">
        <div className="new-form__label">Description</div>
        <div className="new-form__data">{entity.requestor_description}</div>
      </div>
    );
  }

  renderRequestedBy(entity) {
    if (!entity.requested_by_signature) return null;

    return (
      <div className="new-form-field">
        <div className="new-form__label">Requested By</div>
        <div className="new-form__data">{entity.requested_by_signature}</div>
      </div>
    );
  }

  shouldShowCheckNum(paymentMethod, disbursement) {
    const showStatuses = ['Processed', 'Cleared'];

    return (
      paymentMethod === 'Check' &&
      showStatuses.includes(disbursement.status) &&
      disbursement.check_num
    );
  }

  renderAttachments(disbursement, category) {
    const readOnly = !this.state.editing;
    return (
      <DashboardBudgetItemAttachments
        allowInsert={disbursement.payment_method === 'Check'}
        disbursement={disbursement}
        disbursementCategoryAmount={category}
        readOnly={readOnly}
        style="link"
      />
    );
  }

  renderSingleCategory(disbursement, category) {
    const attachments = this.renderAttachments(disbursement, category);
    const fields = this.renderEditableFields(category);
    const description = this.renderDescription(disbursement);
    const requestedBy = this.renderRequestedBy(disbursement);
    const key = `category_${category.dca_id}`;
    const leftWidth = description || requestedBy ? '50%' : '100%';

    return (
      <div key={key}>
        <div
          className="new-form__section new-form--compact new-form__section--inset"
          style={{ display: 'flex' }}
        >
          <div style={{ paddingLeft: 20, width: leftWidth }}>{fields}</div>
          {(description || requestedBy) && (
            <div style={{ paddingLeft: 20, width: '50%' }}>
              {description}
              {requestedBy}
            </div>
          )}
        </div>
        <div
          className="new-form__section new-form--compact new-form__section--inset"
          style={{ display: 'flex' }}
        >
          <div style={{ paddingLeft: 20, width: '100%' }}>{attachments}</div>
        </div>
      </div>
    );
  }

  renderDisbursement() {
    let checkMemoLine2, denyDetails;
    const { disbursement } = this.props;
    const client = disbursement.beneficiary;
    const { toYesNo } = tlFieldTransformers;

    if (['Check', 'External Check'].includes(this.state.paymentMethod)) {
      if (this.state.editing) {
        const formClass = `js-edit-entity-${disbursement.id}`;
        const inputName = 'disbursement[check_memo_line]';

        checkMemoLine2 = (
          <div className="new-form-field">
            <div className="new-form__label">Memo Line 2:</div>
            <div className="new-form__data">
              <form className={formClass} onSubmit={this.updateEntity}>
                <textarea
                  defaultValue={disbursement.check_memo_line_2}
                  name={inputName}
                  style={{ width: '100%' }}
                />
              </form>
            </div>
          </div>
        );
      } else {
        checkMemoLine2 = (
          <div className="new-form-field">
            <div className="new-form__label">Memo Line 2:</div>
            <div className="new-form__data">{disbursement.check_memo_line_2}</div>
          </div>
        );
      }
    }

    const paymentMethod = disbursement.payment_method;
    const { status } = disbursement;

    const card =
      paymentMethod === 'Card' && disbursement.card ? (
        <div className="new-form-field">
          <div className="new-form__label">Card:</div>
          <div className="new-form__data">{disbursement.card.name}</div>
        </div>
      ) : undefined;
    const checkNumber = this.shouldShowCheckNum(paymentMethod, disbursement) ? (
      <div className="new-form-field">
        <div className="new-form__label">Check Number:</div>
        <div className="new-form__data">{disbursement.check_num}</div>
      </div>
    ) : undefined;
    const merchant = disbursement.merchant ? (
      <div className="new-form-field">
        <div className="new-form__label">Merchant:</div>
        <div className="new-form__data">{disbursement.merchant}</div>
      </div>
    ) : undefined;

    const overdrawAllowed = client.organization.able_to_overdraw ? (
      <div className="new-form-field">
        <div className="new-form__label">Overdraw Allowed:</div>
        <div className="new-form__data">{toYesNo(client.overdraw_allowed).toUpperCase()}</div>
      </div>
    ) : undefined;

    const deniedReason =
      status === 'Cancelled' ? (
        <div className="new-form-field">
          <div className="new-form__label">Denied Reason:</div>
          <div className="new-form__data">{disbursement.denial_reason}</div>
        </div>
      ) : undefined;

    const proposal = disbursement.proposer ? (
      <ApproverInfoSection
        action={'proposed'}
        approvalLabel={'Proposed By:'}
        approvalTime={disbursement.proposed_at}
        personText={disbursement.proposer && disbursement.proposer.email}
      />
    ) : undefined;

    const request = (
      <ApproverInfoSection
        action={'requested'}
        approvalLabel={'Requested By:'}
        approvalTime={disbursement.requested_at}
        personText={disbursement.requester_text}
      />
    );

    const approval =
      status !== 'Cancelled' ? (
        <ApproverInfoSection
          action={'approved'}
          approvalLabel={'Approved By:'}
          approvalTime={disbursement.approved_at}
          personText={disbursement.approver_text}
        />
      ) : undefined;

    const seniorApproval = disbursement.senior_approver ? (
      <ApproverInfoSection
        action={'seniorApproved'}
        approvalLabel={'Additional Approval By:'}
        approvalTime={disbursement.senior_approved_at}
        personText={disbursement.senior_approver && disbursement.senior_approver.email}
      />
    ) : undefined;

    const additionalApprover = disbursement.additional_approver;
    const additionalApproval = disbursement.additional_approved_at ? (
      <ApproverInfoSection
        action={'additionalApproved'}
        approvalLabel={'Additional Approval By:'}
        approvalTime={disbursement.additional_approved_at}
        personText={additionalApprover && additionalApprover.email}
      />
    ) : undefined;
    const categories = _map(disbursement.categories, (category) =>
      this.renderSingleCategory(disbursement, category),
    );

    return (
      <div className="table-slidedown">
        <div className="table-slidedown__header">
          <span className="bold">Disbursement Details</span>
          <div className="disbursement-detail-actions fright">
            <DisbursementDetailActions
              canDelete={this.canDeleteDisbursement()}
              canPrint={this.canPrintDisbursement()}
              deletePendingDisbursement={this.deletePendingDisbursement}
              disbursement={this.props.disbursement}
              isEditing={this.state.editing}
              onToggleEdit={this.toggleEdit}
              putOnHold={this.putOnHold}
              removeHold={this.removeHold}
              renderOnHoldDisbursements={this.props.renderOnHoldDisbursements}
              sendDisbursementBackToPending={this.sendDisbursementBackToPending}
              showSendDisbursementBackToPending={this.props.showSendDisbursementBackToPending}
            />
          </div>
        </div>
        {client.three_month_info ? (
          <DetailBalanceInfo
            currentBalance={client.three_month_info.current_balance}
            effectiveBalance={client.three_month_info.effective_balance}
          />
        ) : undefined}
        <div
          className="new-form__section new-form--compact new-form__section--inset"
          style={{ display: 'flex' }}
        >
          <div style={{ width: '50%', paddingRight: 20 }}>
            <div className="new-form-field">
              <div className="new-form__label">Payment Type:</div>
              <div className="new-form__data">{this.paymentType()}</div>
            </div>

            {this.cardDropdown()}
            {this.payeeDropdown()}
            {this.payeeForm()}
          </div>

          <div style={{ width: '50%', paddingLeft: 20 }}>
            {checkMemoLine2}
            {checkNumber}
            {this.postageType()}
            {overdrawAllowed}
            <div>{this.benefitsInformation()}</div>
            {merchant}
            {card}
          </div>
        </div>
        {categories}
        <div
          className="new-form__section new-form__section--last new-form--compact new-form__section--inset"
          style={{ display: 'flex', marginBottom: 0 }}
        >
          <div style={{ width: '50%', paddingRight: 20 }}>
            {deniedReason}
            {denyDetails}
            {proposal}
            {request}
            {approval}
            {seniorApproval}
            {additionalApproval}
          </div>

          <div style={{ width: '50%', paddingLeft: 20 }}>
            <div className="new-form-field">
              <div className="new-form__label">Disbursement ID:</div>
              <div className="new-form__data">{disbursement.id}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderDisbursement()}
        {this.state.isDeleteConfirmationModalOpen && (
          <DisbursementDeleteConfirmationModal
            onCancel={this.closeDeleteConfirmationModal}
            onConfirm={() => {
              DashboardActions.destroyDisbursement(this.props.disbursement);
              this.closeDeleteConfirmationModal();
            }}
          />
        )}
      </div>
    );
  }
}

DashboardDisbursementDetails.propTypes = {
  disbursement: PropTypes.object,
  statusFilter: PropTypes.string,
  editExternalCheckDisposition: PropTypes.bool,
  userRoles: userRoleShape.isRequired,
  payees: PropTypes.array,
  renderOnHoldDisbursements: PropTypes.bool,
  showSendDisbursementBackToPending: PropTypes.bool,
};

DashboardDisbursementDetails.childContextTypes = {
  client: PropTypes.object,
  togglePayeeForm: PropTypes.func,
};
