import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import AccessToCash from './AccessToCash';
import EditRuleSettingsModal from './EditRuleSettingsModal';
import MerchantSettingsV2 from './MerchantSettingsV2';
import PendingMerchantSettings from './PendingMerchantSettings';
import PurchaseSpendingLimit from './PurchaseSpendingLimit';
import SpecificMerchantSettings from './SpecificMerchantSettings';
import SpecificMerchantSettingsModal from './SpecificMerchantSettingsModal';
import SpendingBudget from './SpendingBudget';
import SpendingCategories from './SpendingCategories';
import SpendingCurfew from './SpendingCurfew';
import SpendingMonitorBanners from './SpendingMonitorBanners';
import TemporaryOverrides from './TemporaryOverrides';
import TopLineProtections from './TopLineProtections';
import DashboardActions from 'react/member/actions/dashboard_actions';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import DashboardRulesetStore from 'react/shared/stores/DashboardRulesetStore';
import { asMoney } from 'react/shared/utils/Money';
import { isAbleActProgramAccount } from 'react/shared/utils/account_program_type';
import bindAll from 'react/shared/utils/bind_all';
import { callAPI, getUrl } from 'react/shared/utils/call_api';
import { addRules, removeRules } from 'react/shared/utils/ruleset';

export default class SpendingMonitor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editModalElement: null,
      specificMerchantSettingsModalOpen: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardRulesetStore.on('accountInfo.fetch', this.onAccountInfoLoaded);
    DashboardRulesetStore.on('blockRules.updated', this.onBlockRulesUpdated);
    DashboardRulesetStore.on('ruleset.destroyComplete', this.closeEditModal);
    DashboardRulesetStore.on('ruleset.destroyComplete', this.onBlockRulesUpdated);
    DashboardRulesetStore.on('rulesetInfo.fetch', this.onRulesetInfoLoaded);
    DashboardRulesetStore.on('organizationInfo.fetch', this.onOrganizationInfoLoaded);

    if (!this.state.accountLoaded || !this.state.rulesetLoaded || !this.state.organizationLoaded) {
      DashboardActions.fetchAccountInfo(this.props.accountId);
      DashboardActions.fetchRulesetInfo(this.props.accountId);
      DashboardActions.fetchOrganizationInfo(this.props.accountId);
    }
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  componentWillUnmount() {
    DashboardRulesetStore.off('accountInfo.fetch', this.onAccountInfoLoaded);
    DashboardRulesetStore.off('blockRules.updated', this.onBlockRulesUpdated);
    DashboardRulesetStore.off('ruleset.destroyComplete', this.closeEditModal);
    DashboardRulesetStore.off('ruleset.destroyComplete', this.onBlockRulesUpdated);
    DashboardRulesetStore.off('rulesetInfo.fetch', this.onRulesetInfoLoaded);
    DashboardRulesetStore.off('organizationInfo.fetch', this.onOrganizationInfoLoaded);
  }

  onAccountInfoLoaded() {
    this.setState({
      accountLoaded: true,
      programName: DashboardRulesetStore.getProgramName(),
    });
  }

  onRulesetInfoLoaded() {
    this.setState({
      blockRules: DashboardRulesetStore.getBlockRules(),
      budgetAmount: DashboardRulesetStore.getBudgetAmount(),
      budgetPeriod: DashboardRulesetStore.getBudgetPeriod(),
      pendingRules: DashboardRulesetStore.getPendingRules(),
      remainingBudgetAmount: DashboardRulesetStore.getRemainingBudgetAmount(),
      rulesetLoaded: true,
    });
  }

  onOrganizationInfoLoaded() {
    this.setState({
      showBudgetSettings: DashboardRulesetStore.getShowBudgetSettings(),
      showCurfewSettings: DashboardRulesetStore.getShowCurfewSettings(),
      organizationLoaded: true,
    });
  }

  onBlockRulesUpdated() {
    this.setState({
      blockRules: DashboardRulesetStore.getBlockRules(),
      budgetAmount: DashboardRulesetStore.getBudgetAmount(),
      budgetPeriod: DashboardRulesetStore.getBudgetPeriod(),
      remainingBudgetAmount: DashboardRulesetStore.getRemainingBudgetAmount(),
    });
  }

  addBlockRules(changedRules, elementClicked, afterAddCallback) {
    const url = getUrl('add_block_rules', this.props.accountId);

    // update state in place
    const updatedBlockRules = addRules(this.state.blockRules, changedRules);
    this.setState({
      blockRules: updatedBlockRules,
    });

    // call API to update data on server, then update state again with response
    return callAPI(url, changedRules, elementClicked).then((data) => {
      DashboardRulesetStore.updateBudgetAmount(data.budget_amount);
      DashboardRulesetStore.updateBudgetPeriod(data.budget_period);
      DashboardRulesetStore.updateRemainingBudgetAmount(data.calculate_budget_remaining_amt);
      DashboardRulesetStore.updateBlockRules(data.block_rules);
      if (afterAddCallback) afterAddCallback();
    });
  }

  removeBlockRules(changedRules, elementClicked, afterRemoveCallback) {
    const url = getUrl('remove_block_rules', this.props.accountId);
    // update state in place
    const updatedBlockRules = removeRules(this.state.blockRules, changedRules);
    this.setState({ blockRules: updatedBlockRules });

    // call API to update data on server, then update state again with response
    return callAPI(url, changedRules, elementClicked).then((data) => {
      DashboardRulesetStore.updateBudgetAmount(data.budget_amount);
      DashboardRulesetStore.updateBudgetPeriod(data.budget_period);
      DashboardRulesetStore.updateRemainingBudgetAmount(data.calculate_budget_remaining_amt);
      DashboardRulesetStore.updateBlockRules(data.block_rules);
      if (afterRemoveCallback) afterRemoveCallback();
    });
  }

  refreshPendingRules(data) {
    this.setState({
      pendingRules: data['pending_rules'],
    });
  }

  showPendingRulesSection() {
    return this.state.pendingRules && this.state.pendingRules.length > 0;
  }

  revealEditModal(element) {
    this.setState({
      editRuleSettingsModalOpen: true,
      editModalElement: element,
    });
  }

  closeEditModal() {
    this.setState({
      editRuleSettingsModalOpen: false,
      editModalElement: null,
    });
  }

  displayRemainingBudget() {
    if (this.state.budgetAmount && this.state.budgetPeriod) {
      let period = this.state.budgetPeriod;
      period = period.charAt(0).toUpperCase() + period.slice(1);
      const remaining = this.state.remainingBudgetAmount;
      const amount = this.state.budgetAmount;
      return `${period} budget: ${asMoney(remaining)} remains of ${asMoney(amount)}`;
    }
  }

  handleClickAddMerchants() {
    this.setState({ specificMerchantSettingsModalOpen: true });
  }

  closeModal() {
    this.setState({ specificMerchantSettingsModalOpen: false });
  }

  renderCashSettingsSection() {
    const { blockRules, programName } = this.state;

    const cashSettingsSection = (
      <div className="cash-settings-section">
        <section className="tab-section">
          <SubSectionHeader first>Access to cash</SubSectionHeader>
          <AccessToCash
            addBlockRules={this.addBlockRules}
            block_rules={blockRules}
            removeBlockRules={this.removeBlockRules}
          />
        </section>
      </div>
    );

    if (!isAbleActProgramAccount(programName)) return cashSettingsSection;

    return (
      <InfoTooltip
        id="cash-settings"
        placement="right"
        showOnInit={isAbleActProgramAccount(programName)}
        tooltipClass="tooltip-cash-settings"
        tooltipText={`Your ${programName} card does not allow access to cash.`}
      >
        <div className="disabling_section_overlay">{cashSettingsSection}</div>
      </InfoTooltip>
    );
  }

  render() {
    const { accountLoaded, blockRules, programName, rulesetLoaded, organizationLoaded } =
      this.state;

    const classes = classNames({
      'read-only-disabled': !this.props.canEdit,
      relative: true,
      'text-left': true,
    });

    if (!accountLoaded || !rulesetLoaded || !organizationLoaded) {
      return <LoadingIndicator containerStyle={{ marginTop: 10 }} />;
    }

    return (
      <div className={classes}>
        <SpendingMonitorBanners
          allowOverrideEnabled={!!blockRules['allowAllTransactions']}
          blockOverrideEnabled={!!blockRules['blockAllTransactions']}
          programName={programName}
          removeBlockRules={this.removeBlockRules}
        />
        {this.renderCashSettingsSection()}
        <section className="tab-section">
          <SubSectionHeader first>Top-line protections</SubSectionHeader>
          <TopLineProtections
            addBlockRules={this.addBlockRules}
            block_rules={blockRules}
            programName={programName}
            removeBlockRules={this.removeBlockRules}
          />
        </section>
        {$tlf?.flipperEnableNewMerchantExceptions && (
          <MerchantSettingsV2 cardReference={this.props.cardReference} scope={this.props.scope} />
        )}
        {!$tlf?.flipperEnableNewMerchantExceptions && (
          <>
            <section className="tab-section">
              <SubSectionHeader style={{ marginBottom: '0px' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}
                >
                  Merchant settings
                  <TrueLinkButton
                    id="merchant-setting"
                    onClick={this.handleClickAddMerchants}
                    variant="primary"
                  >
                    + Add merchant setting
                  </TrueLinkButton>
                </div>
                <SpecificMerchantSettingsModal
                  accountId={this.props.accountId}
                  closeModal={this.closeModal}
                  modalOpen={this.state.specificMerchantSettingsModalOpen}
                />
              </SubSectionHeader>
              <SpecificMerchantSettings
                accountId={this.props.accountId}
                addBlockRules={this.addBlockRules}
                block_rules={blockRules}
                showEditModal={this.revealEditModal}
              />
              <EditRuleSettingsModal
                accountId={this.props.accountId}
                closeModal={this.closeEditModal}
                editModalElement={this.state.editModalElement}
                key={this.state.editModalElement}
                modalOpen={this.state.editRuleSettingsModalOpen}
              />
            </section>

            <section className={`tab-section${this.showPendingRulesSection() ? '' : ' hidden'}`}>
              <SubSectionHeader style={{ marginBottom: '0px' }}>
                Pending merchant settings &nbsp;
                <div
                  className="sub_section_header__right"
                  style={{ lineHeight: '48px', fontStyle: 'italic' }}
                >
                  These settings aren't in effect yet. We'll notify you as soon as they are up and
                  running.
                </div>
              </SubSectionHeader>
              <PendingMerchantSettings pending_rules={this.state.pendingRules} />
            </section>
          </>
        )}
        <section className="tab-section" style={{ marginTop: '30px' }}>
          <SubSectionHeader style={{ marginBottom: '0px' }}>Spending categories</SubSectionHeader>
          <SpendingCategories
            addBlockRules={this.addBlockRules}
            blockRules={blockRules}
            programName={programName}
            removeBlockRules={this.removeBlockRules}
          />
          <br />
        </section>
        <section className="tab-section" hidden={!this.state.showCurfewSettings}>
          <SubSectionHeader>Spending curfew</SubSectionHeader>
          <SpendingCurfew
            addBlockRules={this.addBlockRules}
            blockRules={blockRules}
            removeBlockRules={this.removeBlockRules}
          />
        </section>
        <section className="tab-section" hidden={!this.state.showBudgetSettings}>
          <SubSectionHeader>Spending budget</SubSectionHeader>
          <SpendingBudget
            addBlockRules={this.addBlockRules}
            block_rules={blockRules}
            removeBlockRules={this.removeBlockRules}
          />
          <div style={{ marginTop: '15px' }}>{this.displayRemainingBudget()}</div>
        </section>
        <section className="tab-section">
          <SubSectionHeader>Purchase spending limit</SubSectionHeader>
          <PurchaseSpendingLimit
            addBlockRules={this.addBlockRules}
            block_rules={blockRules}
            removeBlockRules={this.removeBlockRules}
          />
        </section>
        <section className="tab-section">
          <SubSectionHeader>Temporary overrides</SubSectionHeader>
          <TemporaryOverrides
            addBlockRules={this.addBlockRules}
            block_rules={blockRules}
            removeBlockRules={this.removeBlockRules}
          />
        </section>
        <br />
      </div>
    );
  }
}

SpendingMonitor.propTypes = {
  accountId: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
  cardReference: PropTypes.string,
  scope: PropTypes.string,
};
