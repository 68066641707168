import PropTypes from 'prop-types';
import React from 'react';
import SpecificMerchantSettingsModal from './SpecificMerchantSettingsModal';
import SpecificRuleLine from './SpecificRuleLine';
import { capitalizeWords } from 'react/shared/utils/Strings';
import bindAll from 'react/shared/utils/bind_all';

export default class SpecificMerchantSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = { specificMerchantSettingsModalOpen: false };

    bindAll(this);
  }

  handleClickAddMerchants() {
    this.setState({ specificMerchantSettingsModalOpen: true });
  }

  // Used to sort the merchant rows by their text attribute
  compare(a, b) {
    if (a.text + a.ruleType < b.text + b.ruleType) return -1;
    if (a.text + a.ruleType > b.text + b.ruleType) return 1;
    return 0;
  }

  closeModal() {
    this.setState({ specificMerchantSettingsModalOpen: false });
  }

  specificMerchantSettingsModal() {
    return (
      <SpecificMerchantSettingsModal
        accountId={this.props.accountId}
        closeModal={this.closeModal}
        modalOpen={this.state.specificMerchantSettingsModalOpen}
      />
    );
  }

  // Takes in a ruleType and returns data for all the rows of that type as an array of js objects
  getRowData(ruleType) {
    const block_rules = this.props.block_rules;

    // ruleType will be either MerchantCluster, Merchant, or MerchantName
    const denylistRuleVal = `denylist${ruleType}s`;
    const allowlistRuleVal = `allowlist${ruleType}s`;
    const maxTxSizeRuleVal = `maxTransactionSizeBy${ruleType}`;

    const denylist = block_rules[denylistRuleVal] || [];
    const allowlist = block_rules[allowlistRuleVal] || [];
    const merchants = [...denylist, ...allowlist];

    return merchants.map((name) => {
      const checkedRules = {};
      const unCheckedRules = {};
      checkedRules[denylistRuleVal] = name;
      unCheckedRules[allowlistRuleVal] = name;

      const isChecked = denylist.includes(name);
      const spendingLimit = block_rules[maxTxSizeRuleVal] && block_rules[maxTxSizeRuleVal][name];
      const text = capitalizeWords(name);

      // Generate array of row objects
      const returnable = {
        text,
        is_checked: isChecked,
        checkedRules,
        unCheckedRules,
        ruleType,
        spendingLimit,
      };
      return returnable;
    });
  }

  createMerchantLine(row) {
    return (
      <SpecificRuleLine
        addBlockRules={this.props.addBlockRules}
        checkedRules={row.checkedRules}
        is_checked={row.is_checked}
        key={row.text + row.ruleType}
        limit={row.spendingLimit}
        ruleType={row.ruleType}
        showEditModal={this.props.showEditModal}
        text={row.text}
        unCheckedRules={row.unCheckedRules}
      />
    );
  }

  render() {
    const clusterRows = this.getRowData('MerchantCluster');
    const merchantRows = this.getRowData('Merchant');
    const merchantNameRows = this.getRowData('MerchantName');
    const rows = clusterRows.concat(merchantRows).concat(merchantNameRows).sort(this.compare);

    if (rows.length > 0) {
      return (
        <div>
          <table className="specific_rules_table">
            <thead>
              <tr>
                <th>My Settings</th>
                <th>Merchant</th>
                <th>Maximum Transaction Size</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>{rows.map(this.createMerchantLine)}</tbody>
          </table>
        </div>
      );
    }
    return <div className="empty-state-box">No merchant settings</div>;
  }
}

SpecificMerchantSettings.propTypes = {
  accountId: PropTypes.string.isRequired,
  addBlockRules: PropTypes.func.isRequired,
  block_rules: PropTypes.object.isRequired,
  showEditModal: PropTypes.func.isRequired,
};
