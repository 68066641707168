import PropTypes from 'prop-types';
import React from 'react';
import _isEmpty from 'underscore/modules/isEmpty';
import _isUndefined from 'underscore/modules/isUndefined';
import _map from 'underscore/modules/map';
import _reduce from 'underscore/modules/reduce';
import _sortBy from 'underscore/modules/sortBy';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import { bigDecimalWithCommas } from 'react/shared/utils/BigDecimal';
import { asMoney } from 'react/shared/utils/Money';
import bindAll from 'react/shared/utils/bind_all';

export default class PortfolioList extends React.Component {
  constructor(props) {
    super(props);

    this.state = { portfolios: props.trust.portfolios };

    bindAll(this);
  }

  onChangePortfolios(portfolios, trustSlug) {
    const { trust } = this.props;

    if (trustSlug === trust.slug) {
      this.setState({ portfolios });
      this.props.setPortfolioCount(portfolios.length, trust.id);
    }
  }

  portfolioList() {
    return _map(
      _sortBy(this.state.portfolios, 'name'),
      (portfolio) => (
        <tr key={portfolio.slug}>
          <td>
            <a
              href={RailsRoutes.dashboard_trust_portfolio_path(
                this.props.trust.slug,
                portfolio.slug,
              )}
            >
              {portfolio.name}
            </a>
          </td>
          <td className="table-cell-right"> {bigDecimalWithCommas(portfolio.total_units)}</td>
          <td className="table-cell-right"> {portfolio.current_unit_value}</td>
          <td className="table-cell-right"> {portfolio.total_asset_value}</td>
        </tr>
      ),
      this,
    );
  }

  totalRow() {
    const total = _reduce(
      this.state.portfolios,
      (memo, portfolio) =>
        memo + Number.parseFloat(portfolio.total_asset_value.replace(/[$,]/g, '')),
      0,
    );

    return (
      <tr>
        <td>
          <strong>TOTAL:</strong>
        </td>
        <td className="table-cell-right" colSpan="3">
          <strong>{asMoney(total)}</strong>
        </td>
      </tr>
    );
  }

  render() {
    const { portfolios } = this.state;

    if (_isUndefined(portfolios)) {
      return <LoadingIndicator />;
    } else if (_isEmpty(portfolios)) {
      return <div className="info-box">No portfolios</div>;
    }
    return (
      <table className="table table-hover">
        <thead>
          <tr>
            <th className="table-cell">Portfolio Name</th>
            <th className="table-cell table-cell-right">Total Units</th>
            <th className="table-cell table-cell-right">Unit Value</th>
            <th className="table-cell table-cell-right">Total Assets</th>
          </tr>
        </thead>

        <tbody>{this.portfolioList()}</tbody>

        <tfoot>{this.totalRow()}</tfoot>
      </table>
    );
  }
}

PortfolioList.propTypes = {
  setPortfolioCount: PropTypes.func.isRequired,
  trust: PropTypes.object.isRequired,
};
