import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import EventsExport from './EventsExport';
import TransactionRow from './TransactionRow';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardWealthAccountEventStore from 'react/member/stores/DashboardWealthAccountEventStore';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import TrueLinkTooltip from 'react/shared/components/true_link/main/TrueLinkTooltip';
import theme from 'react/shared/theme/Theme';
import PALETTE from 'react/shared/theme/palette';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: theme.spacing(5),
  },
  activityIcon: {
    color: PALETTE.grey3,
    marginLeft: theme.spacing(1),
    fontSize: '0.8em',
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  filterFromWrapper: {
    display: 'inline-block',
  },
  filterFromText: {
    paddingRight: theme.spacing(1),
  },
  filterToWrapper: {
    display: 'inline-block',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  filterToText: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  showAllButton: {
    lineHeight: 'normal',
  },
}));

export default function Activity({ beneficiarySlug, clientName, organization }) {
  const dateFormat = 'MM/DD/YYYY';
  const classes = useStyles();
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [currentOpenTransaction, setCurrentOpenTransaction] = useState(null);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const config = {
      minDate: '-1Y',
      maxDate: '+1Y',
      dateFormat: 'mm/dd/yy',
    };
    const fromConfig = {
      onSelect: (date) => {
        // make sure dateTo is larger
        if (dateTo) {
          const momentDateFrom = moment(date, dateFormat);
          let momentDateTo = moment(dateTo, dateFormat);
          if (momentDateTo.isBefore(dateFrom)) {
            momentDateTo = momentDateFrom;
          }
          setDateFrom(momentDateFrom.format(dateFormat));
          setDateTo(momentDateTo.format(dateFormat));
        } else {
          setDateFrom(date);
        }
      },
    };

    $('#date_range_from').datepicker({ ...fromConfig, ...config });
    $('#date_range_to').datepicker({ onSelect: setDateTo, ...config });
  }, [dateTo, dateFrom]);

  const refreshUpcomingTransactions = useCallback(() => {
    DashboardActions.fetchWealthAccountEvents(beneficiarySlug);
  }, [beneficiarySlug]);

  const onChangeTransactions = useCallback(() => {
    setTransactions(DashboardWealthAccountEventStore.getWealthAccountEvents());
  }, []);

  useEffect(() => {
    DashboardWealthAccountEventStore.on('wealthAccountEvents.fetch', onChangeTransactions);
    DashboardWealthAccountEventStore.on('wealthAccountEvent.update', refreshUpcomingTransactions);

    DashboardActions.fetchWealthAccountEvents(beneficiarySlug);

    return () => {
      DashboardWealthAccountEventStore.off('wealthAccountEvents.fetch', onChangeTransactions);
      DashboardWealthAccountEventStore.off(
        'wealthAccountEvent.update',
        refreshUpcomingTransactions,
      );
    };
  }, [beneficiarySlug, onChangeTransactions, refreshUpcomingTransactions]);

  const openTransactionDetails = (transactionId) => {
    setCurrentOpenTransaction(transactionId);
  };

  const closeTransactionDetails = () => {
    setCurrentOpenTransaction(null);
  };

  const getTransactionList = () => {
    const filteredTransactions = transactions.filter((transaction) => {
      if (!!dateFrom && !!dateTo) {
        const momentDateFrom = moment(dateFrom, dateFormat);
        const momentDateTo = moment(dateTo, dateFormat);
        const postedAt = moment(transaction.posted_at);
        return (
          (postedAt.isSame(momentDateFrom) || postedAt.isAfter(momentDateFrom)) &&
          (postedAt.isSame(momentDateTo) || postedAt.isBefore(momentDateTo))
        );
      }
      return true;
    });
    if (filteredTransactions.length === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan={5}>
              No transactions have been completed in this account. Once a transaction is completed,
              it will appear in this section.
            </td>
          </tr>
        </tbody>
      );
    }
    return filteredTransactions.map((transaction, key) => (
      <TransactionRow
        closeTransactionDetails={closeTransactionDetails}
        currentOpenTransaction={currentOpenTransaction}
        key={`${key}investment`}
        openTransactionDetails={openTransactionDetails}
        organization={organization}
        transaction={transaction}
        use="investment"
      />
    ));
  };

  const onFromChange = (evt) => setDateFrom(evt.target.value);

  const onToChange = (evt) => setDateTo(evt.target.value);

  const onClick = () => {
    setDateFrom(null);
    setDateTo(null);
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.header} component="h1" variant="h3">
        Activity
        <TrueLinkTooltip
          tooltipContent={
            <Typography>
              This includes activity such as dividends, interest, trading activity, and transfers to
              the investment account. This does not include activity related to disbursements.
            </Typography>
          }
        >
          <TrueLinkIcon className={classes.activityIcon} icon="circle-question fa-regular" />
        </TrueLinkTooltip>
      </Typography>
      <div>
        <label className={classes.filterFromWrapper} htmlFor="date_range_from">
          <span className={classes.filterFromText}>Filter from:</span>
          <input
            className="datepicker"
            id="date_range_from"
            name="date_range_from"
            onChange={onFromChange}
            placeholder="mm/dd/yyyy"
            type="text"
            value={dateFrom || ''}
          />
        </label>
        <label className={classes.filterToWrapper} htmlFor="date_range_to">
          <span className={classes.filterToText}>To:</span>
          <input
            className="datepicker"
            id="date_range_to"
            name="date_range_to"
            onChange={onToChange}
            placeholder="mm/dd/yyyy"
            type="text"
            value={dateTo || ''}
          />
        </label>
        {dateFrom && dateTo && (
          <button
            className={`${classes.showAllButton} btn btn-default cancel`}
            onClick={onClick}
            type="button"
          >
            Show all
          </button>
        )}
        <span className="pull-right">
          <EventsExport clientId={beneficiarySlug} clientName={clientName} use="investment" />
        </span>
      </div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Date</th>
            <th>Description</th>
            <th>Category</th>
            <th className="table-cell-right">Amount</th>
            <th className="table-cell-right">Details</th>
          </tr>
        </thead>
        {getTransactionList()}
      </table>
    </div>
  );
}

Activity.propTypes = {
  beneficiarySlug: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,
  clientName: PropTypes.string,
};
