import BeneficiaryDashboardActions from 'react/member/actions/beneficiary_dashboard_actions';

const BeneficiaryDashboardAttachmentsStore = flux.createStore({
  attachments: [],
  actions: [BeneficiaryDashboardActions.destroyBudgetItemAttachment],

  destroyBudgetItemAttachment(attachmentId) {
    $.ajax({
      url: RailsRoutes.api_v2_attachment_path(attachmentId, {
        format: 'json',
      }),
      type: 'DELETE',
      dataType: 'json',
    }).done((_result) => {
      this.emit('attachments.destroy', attachmentId);
    });
  },

  exports: {
    // This must be here as an export (instead of an action),
    // because actions have their arguments serialized
    // ...and we need the real input element
    uploadAttachment(fileInputName, fileInputFile, inputNumber) {
      const formData = new window.FormData();
      formData.append(fileInputName, fileInputFile);

      const url = RailsRoutes.api_v2_attachments_path({ format: 'json' });

      this.emit('attachment.uploading');
      $.ajax({
        url,
        xhrFields: { withCredentials: true },
        type: 'POST',
        data: formData,
        enctype: 'multipart/form-data',
        processData: false,
        contentType: false,
        dataType: 'json',
      })
        .done((attachment) => {
          // Ideally this would be tighter controlled, but it is legacy
          // eslint-disable-next-line no-eq-null
          this.emit(
            'attachment.upload',
            { ...attachment.data.attributes, id: attachment.data.id },
            inputNumber === null ? false : inputNumber,
          );
        })
        .fail(() => {
          Truelink.flash(
            'error',
            'An error occurred while uploading the attachment. Please check the file size is less than 25MB.',
          );
          this.emit('attachment.upload', null);
        });
    },
  },
});

export default BeneficiaryDashboardAttachmentsStore;
