import PropTypes from 'prop-types';
import React from 'react';
import { createRoot } from 'react-dom/client';
import Datatable from 'react/shared/components/Datatable';

export default class PaginatedTableBuilder extends React.Component {
  columnDefs() {
    return this.props.columnList.map((column) => ({
      targets: [`js-datatable-col-${this.props.tableFieldConfig[column].attribute}`],
      sortable: !this.props.tableFieldConfig[column].hideSort,
      data: this.props.tableFieldConfig[column].attribute,
      width: 'auto',
      visible: !this.props.tableFieldConfig[column].hidden,
      createdCell: (td, _cellData, rowData, _row, _col) =>
        createRoot(td).render(this.props.tableFieldConfig[column].value(rowData)),
    }));
  }

  columns() {
    return this.props.columnList.map((column) => (
      <th
        className={`js-datatable-col-${this.props.tableFieldConfig[column].attribute}`}
        key={`datatable-${this.props.tableFieldConfig[column].attribute}`}
      >
        {this.props.tableFieldConfig[column].title}
      </th>
    ));
  }

  handleDefaultSort(defaultSort) {
    const { columnList } = this.props;
    if (defaultSort && columnList.includes(defaultSort.key)) {
      return [[columnList.indexOf(defaultSort.key), defaultSort.direction]];
    }
    return [[0, 'asc']];
  }

  handleRender(table) {
    return <div>{table}</div>;
  }

  render() {
    return (
      <Datatable
        columnDefs={this.columnDefs()}
        columns={this.columns()}
        handleRender={this.handleRender}
        initialSort={this.handleDefaultSort(this.props.defaultSort)}
        pageLength={this.props.pageLength || 25}
        route={this.props.indexRoute}
        rowId="id"
        shouldShowSearchBox={this.props.shouldShowSearchBox}
      />
    );
  }
}

PaginatedTableBuilder.propTypes = {
  indexRoute: PropTypes.string.isRequired,
  columnList: PropTypes.array.isRequired,
  tableFieldConfig: PropTypes.object.isRequired,
  shouldShowSearchBox: PropTypes.bool,
  defaultSort: PropTypes.shape({ key: PropTypes.string, direction: PropTypes.string }),
  pageLength: PropTypes.number,
};
