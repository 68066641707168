import axios from 'axios';
import { AXIOS_XHR_CONFIG } from 'react/shared/utils/Axios';
import { asMoney } from 'react/shared/utils/Money';

/**
 * An included object (will also have other fields)
 * @typedef { { id: string, type: string } } ResourceObject
 *
 * A JSON:API relationship
 * @typedef { { id: string, type: string } } Relationship
 */

/**
 * Given an array of `included` resource objects, looks up all `relationships`
 * @param { ResourceObject[] } included An array of included objects to look up the relationships in
 * @param { Relationship | null } relationship A relationship that will be looked up in the included objects
 * @returns  ResourceObject | null
 */
function findIncludedFor(included, relationship) {
  if (relationship === undefined || relationship === null) {
    return null;
  }

  const includedObjects = included.filter(
    (includedObject) =>
      includedObject.type === relationship.type && includedObject.id === relationship.id,
  );

  if (includedObjects.length === 1) {
    return includedObjects[0];
  }

  if (includedObjects.length > 1) {
    throw new Error('more than one included object was found for the relationship');
  }

  return null;
}

/**
 * Given an array of `included` resource objects, looks up all `relationships`
 * @param { ResourceObject[] } included An array of included objects to look up the relationships in
 * @param { Relationship[] } relationships An array of relationships that will be looked up in the included objects
 * @returns  ResourceObject[]
 */
function findAllIncludedFor(included, relationships) {
  return relationships.flatMap((relationship) => findIncludedFor(included, relationship));
}

function flattenJsonApiObject(jsonApiObject) {
  if (jsonApiObject === null || jsonApiObject === undefined) {
    return null;
  }

  const flattenedObject = {
    id: jsonApiObject.id,
    ...jsonApiObject.attributes,
  };

  if (jsonApiObject.meta) {
    flattenedObject.meta = jsonApiObject.meta;
  }

  if (jsonApiObject.relationships) {
    const flattenedRelationships = {};
    for (const [key, val] of Object.entries(jsonApiObject.relationships)) {
      flattenedRelationships[key] = val?.data;
    }

    flattenedObject.relationships = flattenedRelationships;
  }

  return flattenedObject;
}

export function mapJsonApiTrustBeneficiaryToViewModel(jsonApiTrustBeneficiary) {
  if (jsonApiTrustBeneficiary === null || jsonApiTrustBeneficiary === undefined) {
    throw new Error('jsonApiTrustBeneficiary cannot be null');
  }

  const included = jsonApiTrustBeneficiary.included ?? [];

  const trust = flattenJsonApiObject(
    findIncludedFor(included, jsonApiTrustBeneficiary.data.relationships.trust.data),
  );

  const organization = flattenJsonApiObject(
    findIncludedFor(included, jsonApiTrustBeneficiary.data.relationships.organization.data),
  );

  const address = jsonApiTrustBeneficiary.data.relationships.address
    ? flattenJsonApiObject(
        findIncludedFor(included, jsonApiTrustBeneficiary.data.relationships.address.data),
      )
    : null;

  const disabilityDesignation = jsonApiTrustBeneficiary.data.relationships.disabilityDesignation
    ? flattenJsonApiObject(
        findIncludedFor(
          included,
          jsonApiTrustBeneficiary.data.relationships.disabilityDesignation.data,
        ),
      )
    : null;

  const accounts = findAllIncludedFor(
    included,
    jsonApiTrustBeneficiary.data.relationships.accounts.data,
  ).map(flattenJsonApiObject);

  const orgGovtBenefits = jsonApiTrustBeneficiary.included.reduce((result, bene) => {
    if (bene.type === 'government_benefit') {
      result.push(bene);
    }
    return result;
  }, []);

  const tbGovtBenefits = jsonApiTrustBeneficiary.included.reduce((result, bene) => {
    if (bene.type === 'government_benefits_trust_beneficiary') {
      result.push(bene);
    }
    return result;
  }, []);

  const govtBenefitsResults = orgGovtBenefits.reduce((result, orgGovtBenefit) => {
    const individualGovtBenefit = tbGovtBenefits.find(
      (benefit) => benefit.relationships.governmentBenefit.data.id === orgGovtBenefit.id,
    );

    if (individualGovtBenefit === undefined) {
      result.push({ name: orgGovtBenefit.attributes.name, value: 'NO' });
    } else {
      result.push({
        name: orgGovtBenefit.attributes.name,
        value:
          orgGovtBenefit.attributes.showAmount && individualGovtBenefit.attributes?.amount?.amount
            ? asMoney(individualGovtBenefit.attributes.amount.amount)
            : 'YES',
      });
    }
    return result;
  }, []);

  const firstName = jsonApiTrustBeneficiary.data.attributes.firstName;
  const lastName = jsonApiTrustBeneficiary.data.attributes.lastName;
  const nickname = `${firstName} ${lastName}`;
  const cards = included
    .filter((obj) => obj.type === 'card')
    .map((card) => ({
      // api is sort of awkward as far as nickname goes right now
      // probably need to clean this up
      nickname,
      ...flattenJsonApiObject(card),
    }));

  return {
    id: jsonApiTrustBeneficiary.data.id,
    meta: jsonApiTrustBeneficiary.data.meta,
    ...jsonApiTrustBeneficiary.data.attributes,
    trust,
    address,
    accounts,
    cards,
    disabilityDesignation,
    organization,
    govtBenefitsResults,
  };
}

/**
 * Gets a trust beneficiary by its slug
 * @param {string} trustBeneficiarySlug the slug for the given trust beneficiary
 * @returns { Promise<{
 *      id: string,
 *      firstName: string,
 *      lastName: string,
 *      dob: string,
 *      age: number,
 *      mobile: string,
 *      trust: {
 *        id: string,
 *        name: string,
 *      },
 *      accounts: {
 *        id: string,
 *        active: boolean,
 *        fundingType: string,
 *        nickname: string,
 *      }[],
 *      address: {
 *        id: string,
 *        street1: string,
 *        street2: string,
 *        city: string,
 *        state: string,
 *        zip: string,
 *      },
 *      cards: {
 *        id: string,
 *        lastFour: string,
 *        status: string,
 *        nickname: string,
 *        balance: {
 *          amount: number,
 *          currency: string,
 *          timestamp: string,
 *        }
 *      }[]
 *  }> } The trust beneficiary associated with the given slug
 */
export function getTrustBeneficiary(trustBeneficiarySlug) {
  return axios
    .get(
      RailsRoutes.api_v2_trust_beneficiary_path(trustBeneficiarySlug, {
        fields: {
          'organization.analysis':
            'ableToOverdrawDisbursements,showInvestmentDirective,showSoleTrustee',
          'trust_beneficiary.analysis': 'ssnOnFile',
        },
      }),
      {
        _params: {
          include:
            'accounts,accounts.cards,address,trust,organization,organization.governmentBenefits,governmentBenefits,disabilityDesignation',
        },
        get params() {
          return this._params;
        },
        set params(value) {
          this._params = value;
        },
        ...AXIOS_XHR_CONFIG,
      },
    )
    .then((resp) => mapJsonApiTrustBeneficiaryToViewModel(resp.data));
}

/**
 * Available analysis fields
 * @typedef { 'canHaveDisbursementRequested' |
 *            'canHaveCashRaiseRequested' |
 *            'canAddCardForBene' |
 *            'investmentSum' |
 *            'availableCashSum' |
 *            'uniqueAssetsSum' |
 *            'approvedDisbursementsSum' |
 *            'processedDisbursementsSum' } AnalysisFields
 */

/**
 * Raw analysis response
 * @typedef {import("axios").AxiosResponse< {
 *    meta: {
 *      canHaveDisbursementRequested: boolean,
 *      canHaveCashRaiseRequested: boolean,
 *      canAddCardForBene: boolean,
 *      investmentSum: { amount: number, currency: 'USD' },
 *      availableCashSum: { amount: number, currency: 'USD' },
 *      uniqueAssetsSum: { amount: number, currency: 'USD' },
 *      approvedDisbursementsSum: { amount: number, currency: 'USD' },
 *      processedDisbursementsSum: { amount: number, currency: 'USD' }
 *    }
 *  } > } AnalysisResponse
 */

/**
 * Retrieves analysis fields for a trust beneficiary
 * @param {string} trustBeneficiarySlug the slug for the given trust beneficiary<p/>
 * @param { AnalysisFields[] } fields An array of which analysis fields to include in the response
 * @returns  { Promise<{
 *      canHaveDisbursementRequested: boolean,
 *      canHaveCashRaiseRequested: boolean,
 *      canAddCardForBene: boolean,
 *      investmentSum: { amount: number, currency: 'USD' },
 *      availableCashSum: { amount: number, currency: 'USD' },
 *      uniqueAssetsSum: { amount: number, currency: 'USD' },
 *      approvedDisbursementsSum: { amount: number, currency: 'USD' },
 *      processedDisbursementsSum: { amount: number, currency: 'USD' }
 *    }> }
 */
export function getTrustBeneficiaryAnalysis(trustBeneficiarySlug, fields) {
  /** @type  Promise<Response> */
  const resp = axios.get(RailsRoutes.api_v2_trust_beneficiary_path(trustBeneficiarySlug), {
    params: {
      fields: {
        'trust_beneficiary.analysis': fields.join(','),
      },
    },
    ...AXIOS_XHR_CONFIG,
  });

  return resp.then((r) => r.data.data.meta);
}

/**
 * Gets all the positions associated with an trust beneficiary by its slug
 * @param {string} trustBeneficiarySlug the slug for the given trust beneficiary
 * @returns { Promise<{
 *      [
 *        {
 *          "id":string,
 *          "name": string
 *          "wealth_account_name": string,
 *          "position_type": string,
 *          "symbol": string,
 *          "total_value": string,
 *          "unit_value": string,
 *          "units": string,
 *          "value_currency": "USD",
 *          "wealth_account_id": string,
 *        },
 *      ]
 *    }> }
 */

export function getPositionsByTrustBeneficiary(trustBeneficiarySlug) {
  const resp = axios.get(
    RailsRoutes.dashboard_client_positions_path(trustBeneficiarySlug, { format: 'json' }),
    AXIOS_XHR_CONFIG,
  );
  return resp.then((r) => r.data);
}

export function getAccountsAnalysis(accountId, fields) {
  const resp = axios.get(RailsRoutes.analysis_api_v2_account_path(accountId), {
    params: { fields: fields.join(',') },
    ...AXIOS_XHR_CONFIG,
  });
  return resp.then((r) => r.data.meta);
}
