import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DashboardActions from 'react/member/actions/dashboard_actions';
import GenericRadioInput from 'react/shared/components/forms/GenericRadioInput';
import GenericTextInput from 'react/shared/components/forms/GenericTextInput';
import PopUp from 'react/shared/components/popups/PopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import DashboardRulesetStore from 'react/shared/stores/DashboardRulesetStore';
import { callAPI, getUrl } from 'react/shared/utils/call_api';

const useStyles = makeStyles({
  radioWrapper: {
    paddingBottom: '5px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px',
  },
  checkboxField: {
    width: 'auto',
  },
});

export default function EditRuleSettingsModal(props) {
  const classes = useStyles();
  const isChecked = (el, value) => {
    switch (value) {
      case 'block':
        return !!el.props.is_checked;
      case 'allow_with_max':
        return !el.props.is_checked && !!el.props.limit;
      case 'allow':
        return !el.props.is_checked && !el.props.limit;
      default:
        return false;
    }
  };

  const [maxAmount, setMaxAmount] = useState(props?.editModalElement?.props?.limit || null);
  const [allowChecked, setAllowChecked] = useState(
    props.editModalElement ? isChecked(props?.editModalElement, 'allow') : undefined,
  );
  const [blockChecked, setBlockChecked] = useState(
    props.editModalElement ? isChecked(props?.editModalElement, 'block') : undefined,
  );
  const [allowWithMaxChecked, setAllowWithMaxChecked] = useState(
    props.editModalElement ? isChecked(props?.editModalElement, 'allow_with_max') : undefined,
  );

  const ruleType = props?.editModalElement?.props?.ruleType || null;
  const ruleText = props?.editModalElement?.props?.text || null;

  const handleRuleSettingChange = (event) => {
    const values = {
      allowChecked: false,
      blockChecked: false,
      allowWithMaxChecked: false,
    };
    switch (event.target.value) {
      case 'block':
        values.blockChecked = true;
        break;
      case 'allow':
        values.allowChecked = true;
        break;
      case 'allow_with_max':
        values.allowWithMaxChecked = true;
        break;
    }
    setAllowChecked(values.allowChecked);
    setBlockChecked(values.blockChecked);
    setAllowWithMaxChecked(values.allowWithMaxChecked);
  };

  const maxAmountChange = (event) => {
    setMaxAmount(event.target.value);
  };

  const submitForm = () => {
    const url = getUrl('add_block_rules', props.accountId);
    const newRule = {};
    const maxTxRule = {};
    const rules = props.editModalElement.props.checkedRules;
    const ruleKey = Object.keys(rules)[0];
    const value = rules[ruleKey];
    const block = blockChecked;
    const allow = allowChecked;
    const allowMax = allowWithMaxChecked;
    if (block) {
      newRule[`denylist${ruleType}s`] = value;
      maxTxRule[value] = '';
    } else if (allow) {
      newRule[`allowlist${ruleType}s`] = value;
      maxTxRule[value] = '';
    } else if (allowMax) {
      newRule[`allowlist${ruleType}s`] = value;
      maxTxRule[value] = maxAmount;
    }

    if (ruleType.match(/merchant/i)) {
      newRule[`maxTransactionSizeBy${ruleType}`] = maxTxRule;
    }

    callAPI(url, newRule, null).then((data) => {
      DashboardRulesetStore.updateBlockRules(data.block_rules);
      props.closeModal();
    });
  };

  const modalTitle = () => {
    const merchant_text = 'Edit specific merchant settings';
    const cat_text = 'Edit specific category settings';
    if (ruleType) {
      switch (ruleType) {
        case 'MerchantCluster':
          return merchant_text;
        case 'MerchantName':
          return merchant_text;
        case 'Merchant':
          return merchant_text;
        default:
          return cat_text;
      }
    }
  };

  const ruleName = () => {
    const merchant_text = 'Merchant:';
    const cat_text = 'Category:';
    if (ruleType) {
      switch (ruleType) {
        case 'MerchantCluster':
          return merchant_text;
        case 'MerchantName':
          return merchant_text;
        case 'Merchant':
          return merchant_text;
        default:
          return cat_text;
      }
    }
  };

  const deleteRule = (e) => {
    e.preventDefault();
    const checkedRules = props.editModalElement.props.checkedRules;
    DashboardActions.destroyRule(checkedRules, ruleType);
  };

  const footer = (
    <div className={classes.footer}>
      <TrueLinkButton onClick={submitForm} variant="primary">
        Save settings
      </TrueLinkButton>
      <TrueLinkLink onClick={deleteRule}>Delete this merchant</TrueLinkLink>
    </div>
  );

  return (
    <div>
      <PopUp
        footer={footer}
        header={modalTitle()}
        maxWidth={'730px'}
        modalOpen={props.modalOpen}
        onClose={props.closeModal}
      >
        <form className="specific-settings-form">
          <table className="form-table">
            <colgroup>
              <col width="120px" />
            </colgroup>
            <tbody>
              <tr>
                <td className="rule-name"> {ruleName()}</td>
                <td className="rule-text"> {ruleText}</td>
              </tr>
            </tbody>
          </table>
          <hr />
          <table className="form-table">
            <colgroup>
              <col width="120px" />
            </colgroup>
            <tbody>
              <tr>
                <td>Setting:</td>
                <td className={classes.radioWrapper}>
                  <label className="radio_label" htmlFor="edit_rule_setting_block">
                    <GenericRadioInput
                      checked={blockChecked}
                      handleChange={handleRuleSettingChange}
                      id="edit_rule_setting_block"
                      name="edit_rule_setting"
                      value="block"
                    />
                    &nbsp; Block all purchases
                  </label>
                </td>
              </tr>
              <tr>
                <td />
                <td className={classes.radioWrapper}>
                  <label className="radio_label" htmlFor="edit_rule_setting_allow">
                    <GenericRadioInput
                      checked={allowChecked}
                      handleChange={handleRuleSettingChange}
                      id="edit_rule_setting_allow"
                      name="edit_rule_setting"
                      value="allow"
                    />
                    &nbsp; Allow all purchases
                  </label>
                </td>
              </tr>
              <tr>
                <td />
                <td className={classes.radioWrapper}>
                  <label className="radio_label" htmlFor="edit_rule_setting_allow_with_max">
                    <GenericRadioInput
                      checked={allowWithMaxChecked}
                      handleChange={handleRuleSettingChange}
                      id="edit_rule_setting_allow_with_max"
                      name="edit_rule_setting"
                      value="allow_with_max"
                    />
                    &nbsp; Allow all purchases except purchases above a spending limit
                    of&nbsp;&nbsp;&nbsp;${' '}
                    <GenericTextInput
                      className={`max_value checkbox_textfield ${classes.checkboxField}`}
                      defaultValue={String(maxAmount || 0)}
                      maxLength="7"
                      min="5"
                      name="max_amount"
                      onChange={maxAmountChange}
                      placeholder="0"
                      size="5"
                    />
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </PopUp>
    </div>
  );
}

EditRuleSettingsModal.propTypes = {
  accountId: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  editModalElement: PropTypes.object,
  modalOpen: PropTypes.bool,
};

EditRuleSettingsModal.defaultProps = {
  modalOpen: false,
};
