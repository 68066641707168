/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import moment from 'moment-timezone';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import TransactionAttachments from './TransactionAttachments';
import DashboardActions from 'react/member/actions/dashboard_actions';
import CategoryDropdown from 'react/member/components/dashboard/CategoryDropdown';
import bindAll from 'react/shared/utils/bind_all';

export default class TransactionRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMemo: false,
      editedMemoValue: '',
    };

    bindAll(this);
  }

  decimalToString(decimal) {
    const decimalNumber = Number(decimal);
    if (decimal < 0) {
      return numeral(decimalNumber).format('0,0.00');
    }
    return `+ ${numeral(decimalNumber).format('0,0.00')}`;
  }

  toggleDetails() {
    if (this.transactionDetailsOpen()) {
      this.props.closeTransactionDetails();
    } else {
      this.props.openTransactionDetails(this.props.transaction.id);
    }
  }

  flashUploadMessage() {
    Truelink.flash(
      'error',
      'This is a demo account. You cannot associate documents with transactions.',
    );
  }

  updateTransactionCategory(ev) {
    const orgId = this.props.organization.id;
    const data = {
      category: ev.target.value,
      id: this.props.transaction.id,
      organization_id: orgId,
    };
    DashboardActions.updateWealthAccountEvent(data, 'category');
  }

  toggleMemoEdit(evt) {
    if (evt) {
      evt.preventDefault();
    }
    this.setState({ editMemo: !this.state.editMemo });
  }

  handleMemoInput(ev) {
    this.setState({ editedMemoValue: ev.target.value });
  }

  updateTransactionMemo(ev) {
    ev.preventDefault();
    const orgId = this.props.organization.id;
    const data = {
      id: this.props.transaction.id,
      memo: this.state.editedMemoValue,
      organization_id: orgId,
    };
    DashboardActions.updateWealthAccountEvent(data, 'memo');
    this.toggleMemoEdit();
  }

  memoField() {
    if (this.state.editMemo == true) {
      return (
        <form onSubmit={this.updateTransactionMemo}>
          <input
            defaultValue={this.props.transaction.memo}
            name="wealth_account_event[memo]"
            onChange={this.handleMemoInput}
            type="text"
          />
          <button className="btn btn-success" style={{ marginLeft: 15 }} type="submit">
            Save
          </button>
          <a onClick={this.toggleMemoEdit} style={{ marginLeft: 7 }}>
            Cancel
          </a>
        </form>
      );
    }
    const memo = this.props.transaction.memo;
    if (!memo) {
      return (
        <p>
          <a onClick={this.toggleMemoEdit}>Add Memo</a>
        </p>
      );
    }

    return (
      <div>
        <span>{memo}</span>
        <a onClick={this.toggleMemoEdit} style={{ fontSize: 14, marginLeft: 7 }}>
          Edit
        </a>
      </div>
    );
  }

  memo() {
    return (
      <tr className="dashboard-table-row">
        <th>Memo:</th>
        <td>{this.memoField()}</td>
      </tr>
    );
  }

  detailsRow() {
    const colSpan = 6;
    const transaction = this.props.transaction;

    if (this.transactionDetailsOpen()) {
      return (
        <tr style={{ background: '#F0F0F0' }}>
          <td colSpan={colSpan} style={{ background: '#F0F0F0' }}>
            <table className="table" style={{ width: '50%', background: '#F0F0F0' }}>
              <tbody style={{ fontSize: 14 }}>
                <tr>
                  <td colSpan="2">
                    <b style={{ fontWeight: 700 }}>Transaction Details</b>
                  </td>
                </tr>
                <tr className="dashboard-table-row">
                  <th>Category:</th>
                  <td>
                    <CategoryDropdown
                      hideLabel
                      initialValue={transaction.category}
                      onChange={this.updateTransactionCategory}
                      organizationSlug={this.props.organization.slug}
                    />
                  </td>
                </tr>
                {this.memo()}
                <tr className="dashboard-table-row">
                  <th>Attachments:</th>
                  <td>
                    <TransactionAttachments wealthAccountEventId={transaction.id} />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      );
    }
  }

  balanceDecimalToString(decimal) {
    const numberDecimal = Number(decimal);
    if (numberDecimal < 0) {
      return numeral(numberDecimal).format('0,0.00');
    }
    return `(${numeral(numberDecimal).format('0,0.00')})`;
  }

  transactionDetailsOpen() {
    return this.props.currentOpenTransaction == this.props.transaction.id;
  }

  rowByUse() {
    const transaction = this.props.transaction;
    return (
      <tr>
        <td>{moment(transaction.posted_at).format('MM/DD/YYYY')}</td>
        <td>{transaction.comment}</td>
        <td>{transaction.category}</td>
        <td className="table-cell-right">{this.decimalToString(transaction.amount)}</td>
        <td className="table-cell-right">
          <a onClick={this.toggleDetails}>{this.transactionDetailsOpen() ? 'Close' : 'View'}</a>
        </td>
      </tr>
    );
  }

  render() {
    return (
      <tbody>
        {this.rowByUse()}
        {this.detailsRow()}
      </tbody>
    );
  }
}

TransactionRow.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  transaction: PropTypes.object.isRequired,
  currentOpenTransaction: PropTypes.number,
  openTransactionDetails: PropTypes.func.isRequired,
  closeTransactionDetails: PropTypes.func.isRequired,
};
