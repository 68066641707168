import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SpendingSettingsSdkContainer from 'react/member/card/components/spending_monitor_tab/SpendingSettingsSdkContainer';
import SpendingMonitor from 'react/shared/card/components/spending_monitor_tab/SpendingMonitor';
import TrueLinkBanner from 'react/shared/components/true_link/lab/TrueLinkBanner';

const useStyles = makeStyles({
  sdkContainer: {
    '&.SpendingSettings input[type="text"]': {
      border: '0',
      boxShadow: 'none',
      padding: '8.5px 14px 8.5px 0',
      margin: '0',
      height: '40px',
    },

    '&.SpendingSettings input[disabled]': {
      backgroundColor: '#fff',
    },

    '&.SpendingSettings h1': {
      display: 'none',
    },
    '&.SpendingSettings h2': {
      fontSize: '26px',
    },
  },
  '@global': {
    'input#merchant_limit[type="number"], input#merchant_name[type="text"]': {
      height: '40px !important',
      marginBottom: '0 !important',
    },
    'input#merchant-cluster[type="text"]': {
      boxShadow: 'none',
      border: 'none',
      margin: '0',
    },
    '#merchant_info': {
      boxShadow: 'none',
    },
  },
});

export default function CardSpendingMonitorTabContainer({
  accountId,
  cardReference,
  canEdit,
  hideCashWithdrawalLimit,
  scope,
  visibilityConfig,
}) {
  const classes = useStyles();

  const [displaySdkView, setDisplaySdkView] = useState($tlf.flipperEnableSpendingSettingsSdkToggle);

  const handleChange = (event) => {
    setDisplaySdkView(event.target.checked);
  };

  return (
    <div className={classes.sdkContainer}>
      {$tlf.flipperEnableSpendingSettingsSdkToggle && (
        <>
          <TrueLinkBanner
            dismissable
            text={
              'Our improved navigation makes updating settings easier. The settings on your True Link Cards have not changed, we’re just displaying them to you in a new format. For a limited time, you can return to the classic experience in the toggle below.'
            }
            title={'Our Spending Settings have been updated!'}
            variant="promo"
          />
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="flex-end"
            sx={{ p: 2 }}
          >
            <div>Viewing Updated Experience</div>
            <Switch checked={displaySdkView} onChange={handleChange} />
          </Grid>
        </>
      )}
      {!displaySdkView && (
        <SpendingMonitor
          accountId={accountId}
          canEdit={canEdit}
          cardReference={cardReference}
          scope={scope}
        />
      )}
      {displaySdkView && (
        <SpendingSettingsSdkContainer
          accountId={accountId}
          canEdit={canEdit}
          hideCashWithdrawalLimit={hideCashWithdrawalLimit}
          visibilityConfig={visibilityConfig}
        />
      )}
    </div>
  );
}

CardSpendingMonitorTabContainer.propTypes = {
  accountId: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
  hideCashWithdrawalLimit: PropTypes.bool,
  cardReference: PropTypes.string,
  scope: PropTypes.string,
  visibilityConfig: PropTypes.object,
};
